import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import Classnames from "classnames";
import WhiteLogo from '../../assets/images/white-logo.png';
import {NavLink, useLocation} from 'react-router-dom';
import style from './style.module.scss';

const Sidebar = ({mode, children}) => {

    const location = useLocation()

    const tabs = [
        // {
        //     logo: "fas fa-tachometer-alt-slowest",
        //     title: "Dashboard",
        //     target:"/dashboard"
        // },
        {
            logo: "fas fa-user-injured",
            title: "Patients",
            target:"/patients"
        }
    ]

    return (
        <aside className={style["sidebar-root"]}>
            <div className={style["logo-wrapper"]}>
                <img className={style["side-logo"]} src={WhiteLogo} alt="logo" />
            </div>
            <ul className={style["list-menu"]}>
                {
                    tabs.map(item => (
                        <li key={item.title} className={location.pathname === item.target ? style["sidebar-list--active"] : style["sidebar-list"]}>
                            <NavLink to={item.target} className={style["item-link"]}>
                                <i className={Classnames([item.logo], style["item-icon"])}></i>
                                <span className={style["nav-text"]}>{item.title}</span>
                            </NavLink>
                        </li>
                    ))
                }
            </ul>
            {children}
        </aside>
    )
}

const mapStateToProps = (state) => ({
    mode: state.auth.mode
});

const mapDispatchToProps = (dispatch) => ({
    // startLoginUser: (body, remember) => dispatch(startLoginUser({body}, remember))
})

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);