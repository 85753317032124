import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Input } from 'reactstrap';
import Classnames from 'classnames';
import { useHistory } from 'react-router-dom';
import { changeStudy } from '../../actions/common';
import style from './style.module.scss';
import { subtrials } from '../../pages/AdminPatients/services';
import { AppAlert } from '../../utils/Notification';

const Header = ({ token, study, changeStudy }) => {

    const [subtrialsList, setSubtrialsList] = useState([])
    const [selectedSubtrial, setSelectedSubtrial] = useState(' ')

    useEffect(() => {
        subtrials({ header: { authorization: `Bearer ${token}` } })
            .then((response) => {
                setSubtrialsList(response.data)
                if (response.data.length > 0) {
                    const selectedStudy = localStorage.getItem('selected_trial')
                    if (!!selectedStudy){
                        const permittedStudies = response.data.map(el => el[0])
                        if (permittedStudies.includes(selectedStudy)) {
                            changeStudy(selectedStudy)
                        } else {
                            changeStudy(response.data[0][0])
                        }
                    } else {
                        changeStudy(response.data[0][0] ?? '')
                    }
                }
            })
            .catch((error) => {
                AppAlert(400, "Failed to fetch subtrials")
            })
    }, [])

    const history = useHistory()

    const generateTitle = () => {
        const pathname = history.location.pathname.split('/')[1]
        switch (pathname) {
            case "patients":
                return "Patients";
            case "activities":
                return "Activities";
            case "events":
                return "Events";
            default:
                return "";
        }
    }

    const onStudyChange = (e) => {
        const value = e.target.value
        localStorage.setItem('selected_trial', value)
        changeStudy(value)
    }

    return (
        <div className={style["head-root"]}>
            <span className={style["head-title"], 'd-none d-sm-block'}>{generateTitle()}</span>
            <div className='col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2'>
                <Input type="select" className={Classnames("form-control")} id="type-input" value={study} onChange={onStudyChange}>
                    {
                        subtrialsList.map((el) => <option value={el[0]}>{el[1]}</option>)
                    }
                </Input>
            </div>
        </div>
    );
}


const mapStateToProps = (state) => ({
    token: state.auth.access_token,
    study: state.common.type
});

const mapDispatchToProps = (dispatch) => ({
    changeStudy: (newValue) => dispatch(changeStudy({ type: newValue }))
})

export default connect(mapStateToProps, mapDispatchToProps)(Header);