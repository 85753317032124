import React from 'react';
import { connect } from 'react-redux';
import { Route, Redirect, withRouter } from 'react-router-dom';
import Classnames from 'classnames';
import Header from '../../components/Header';
import Sidebar from '../../components/Sidebar';
import style from './style.module.scss';
import { logoutUser } from '../../actions/users';


export const PrivateRoute = ({
  isAuthenticated,
  startLogout,
  history,
  staticContext,
  component: Component,
  ...rest
}) => {

  const onLogout = () => {
      localStorage.removeItem("access_token")
      localStorage.removeItem("refresh_token")
      localStorage.removeItem("profile")
      localStorage.removeItem('selected_trial')
      history.replace("/")
      startLogout()
  }

  return (
    <Route {...rest} component={(props) => (
      isAuthenticated ? (
        <div className={style["root"]}>
          <div className={style["sidebar"]}>
            <Sidebar>
              <button className={Classnames("btn", "btn-info", "btn-md", style["logout-btn"])} onClick={onLogout}>Logout</button>
            </Sidebar>
          </div>
          <div className={style["main-content"]}>
            <Header/>
            <Component {...props}/>
          </div>
        </div>
      ) : (
          <Redirect to="/" />
        )
    )} />
  );
}
   
const mapStateToProps = (state) => ({
  isAuthenticated: !!state.auth.access_token
});

const mapDispathToProps = (dispatch) => ({
  startLogout: () => dispatch(logoutUser())
})

export default connect(mapStateToProps, mapDispathToProps)(withRouter(PrivateRoute));
