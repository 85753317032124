import { axiosAuthenticatedClient, axiosClient } from '.';
import client from './SharedConfig';

export const loginUser = ({body={}} = {}) => {
    const config = {
        method: 'POST',
        url: '/api/v1/account/login/',
        data: body,
    };
    return axiosClient.request(config )
};

export const refreshToken = (refreshToken) => {
    const config = {
        method: 'POST',
        url: '/api/v1/account/refresh/',
        data: {refresh: refreshToken},
    };
    return axiosClient.request(config )
};


export const usersList = ({header={}, parameter={}} = {}) => {
    const config = {
        method: 'GET',
        url: 'users/admin/list',
        params:{
            ...parameter,
            code: process.env.REACT_APP_CODE,
        },
        headers:{
            ["X-APP-ID"]: process.env.REACT_APP_ID,
            ...header
        }
    };
    return client.request(config )
}

export const userVerification = ({header={}} = {}) => {
    const config = {
        method: 'GET',
        url: '/api/v1/admin-panel/subtrials/',
        headers:{
            ...header
        }
    };
    return axiosAuthenticatedClient.request(config )
}

export const logoutUser = ({header={}} = {}) => {
    const config = {
        method: 'POST',
        url: 'users/logout',
        params:{
            code: process.env.REACT_APP_CODE,
        },
        headers:{
            ["X-APP-ID"]: process.env.REACT_APP_ID,
            ...header
        }
    };
    return client.request(config )
}

export const userDetails = ({header={}, userId="0"} = {}) => {
    const config = {
        method: 'GET',
        url: `users/admin/${userId}`,
        params:{
            code: process.env.REACT_APP_CODE,
        },
        headers:{
            ["X-APP-ID"]: process.env.REACT_APP_ID,
            ...header
        }
    };
    return client.request(config )
}

export const userUpdate = ({header={}, body={}, userId="0"} = {}) => {
    const config = {
        method: 'PUT',
        url: `users/admin/${userId}`,
        params:{
            code: process.env.REACT_APP_CODE,
        },
        headers:{
            ["X-APP-ID"]: process.env.REACT_APP_ID,
            ...header
        },
        data: body
    };
    return client.request(config )
}