import client from '../../services/SharedConfig';

export const fetchActivities = ({parameter={}, header={}, id=0} = {}) => {
    const config = {
        method: 'GET',
        url: `/api/v1/admin-panel/patient/${id}/activities/`,
        params: {
            ...parameter
        },
        headers:{
            ...header
        }
    };
    return client.request(config )
};

export const downloadActivityExport = ({header={}, study=''} = {}) => {
    const config = {
        method: 'GET',
        responseType: "blob",
        url: `/api/v1/admin-panel/patient/activity/export/?subtrial=${study}`,
        headers:{
            ...header
        }
    };
    return client.request(config )
};