import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { Table, ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { downloadExport, fetchPatients, fetPatientDetails, toggleWithdraw } from './services';
import style from "./style.module.scss"
import Classnames from "classnames";
import moment from "moment";
import _ from "lodash";
import Skeleton from 'react-loading-skeleton';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { AppAlert } from "../../utils/Notification"
import TableFilter from "../../components/TableFilter"
import { baseURL } from '../../services/SharedConfig';
import { downloadEventExport } from '../EventsLog/services';
import { downloadActivityExport } from '../ActivitiesLog/services';

const AdminPatients = ({ token, history, study }) => {

    const initSearchQueries = {
        limit: 25,
        page: 1,
        // search: "",
        // sort: "0",
        // dir: "0",
        // lite: true
    }

    const [locations, setLocations] = useState([])
    const [isEnd, setEnd] = useState(false)
    const [detailModal, setDetailModal] = useState(false);
    const [searchQueries, setSearchQueries] = useState({ ...initSearchQueries })
    const [searchText, setSearchText] = useState("")
    const [totalCount, setTotalCount] = useState(-1)
    const debounceRef = useRef({ latest: undefined })
    const [isLoading, setLoading] = useState(false)
    const [isLoadingDetail, setLoadingDetail] = useState(false)
    const [detailInfo, setDetailInfo] = useState(undefined)
    const [detailTargetId, setDetailTargetId] = useState(undefined);
    const [actionRowId, setActionRowId] = useState(undefined)

    useEffect(() => {
        if (study && !isLoading) {
            setLoading(true)
            const newSearchQuery = { lite: searchQueries.lite, page: searchQueries.page, size: searchQueries.size }
            if (!!searchQueries.search) {
                newSearchQuery["search"] = searchQueries.search
            }
            if (!!searchQueries.sort) {
                newSearchQuery["sort"] = searchQueries.sort
            }
            if (!!searchQueries.dir) {
                newSearchQuery["dir"] = searchQueries.dir
            }
            fetchPatients({ parameter: { limit: searchQueries.limit, offset: (searchQueries.page -1)* searchQueries.limit, subtrial: study }, header: { authorization: `Bearer ${token}` } })
                .then((response) => {
                    setLocations(response.data.results || [])
                    setTotalCount(response.data.count)
                    setEnd(!response.data.next)
                })
                .catch(error => {
                    if (!error.response) {
                        AppAlert(500, "cannot reach server")
                    }
                    else {
                        AppAlert(error.response.status, error.response.data.detail)
                    }
                })
                .finally(() => {
                    setLoading(false)
                })
        }
    }, [searchQueries, study])

    useEffect(() => {
        setSearchQueries({ ...searchQueries, page: 1 })
    }, [study])

    useEffect(() => {
        if (!!detailTargetId) {
            setLoadingDetail(true)
            fetPatientDetails({ id: +detailTargetId, header: { authorization: `Bearer ${token}`} })
                .then((response) => {
                    setDetailInfo(response.data)
                    console.log(response.data);
                    setLoadingDetail(false)
                })
                .catch(error => {
                    if (!error.response) {
                        AppAlert(500, "cannot reach server")
                    }
                    else {
                        AppAlert(error.response.status, error.response.data.detail)
                    }
                    setLoadingDetail(false)
                    setTimeout(() => {
                        toggleDetailModal()
                    }, 1000)
                })
        }
    }, [detailTargetId])

    const onNextPage = () => {
        if (!isEnd) {
            setSearchQueries({ ...searchQueries, page: searchQueries.page + 1 })
        }
    }

    const onPrevPage = () => {
        if (searchQueries.page > 1) {
            setSearchQueries({ ...searchQueries, page: searchQueries.page - 1 })
        }
    }

    const onNewPatient = () => {
        history.push("/shared/patients/new")
    }

    const onConfirm = () => {
        // history.push("/admin/patients/confirm")xx
        // window.open(`${baseURL}/api/admin/patients/export/`)
        downloadExport({ header: { authorization: `Bearer ${token}` }, study })
            .then((response) => {
                console.log(response);
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'patients_export.csv');
                document.body.appendChild(link);
                link.click();
            })
    }

    const onConfirmActivity = () => {
        // history.push("/admin/patients/confirm")xx
        // window.open(`${baseURL}/api/admin/patients/export/`)
        downloadActivityExport({ header: { authorization: `Bearer ${token}` }, study })
            .then((response) => {
                console.log(response);
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'activity_export.csv');
                document.body.appendChild(link);
                link.click();
            })
    }

    const onConfirmEvent = () => {
        // history.push("/admin/patients/confirm")xx
        // window.open(`${baseURL}/api/admin/patients/export/`)
        downloadEventExport({ header: { authorization: `Bearer ${token}` }, study })
            .then((response) => {
                console.log(response);
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'events_export.csv');
                document.body.appendChild(link);
                link.click();
            })
    }

    const onPatienActivities = (id, study) => {
        history.push(`/activities/${id}/${study}`)
    }

    const onPatientEvents = (id, study) => {
        history.push(`/events/${id}/${study}`)
    }

    const onWidthdrawToggle = (personId) => {
        if (!isLoading) {
            setLoading(true)
            toggleWithdraw({ personId, header: { authorization: `Bearer ${token}` } })
                .then((response) => {
                    setLoading(false)
                    setSearchQueries({ ...searchQueries, refresh: !searchQueries.refresh })
                    AppAlert(200, 'Widthdraw state is updated')
                })
                .catch((error) => {
                    setLoading(false)
                    AppAlert(400, "Failed to update widthdraw state")
                })
        }
    }

    const onPatientSeizures = (id) => {
        history.push(`/shared/seizures/${id}`)
    }

    const onActionDropdownToggle = (rowId, e) => {
        e.stopPropagation()
        if (!isLoading) {
            if (!!actionRowId && actionRowId === rowId) {
                setActionRowId(undefined)
            }
            else {
                setActionRowId(rowId)
            }
        }
    }

    const onOrderChange = (e) => {
        const val = e.target.value
        if (searchQueries.dir !== val) {
            setSearchQueries({ ...searchQueries, dir: val })
        }
    }

    const onSortChange = (e) => {
        const val = e.target.value
        if (searchQueries.sort !== val) {
            setSearchQueries({ ...searchQueries, sort: val })
        }
    }

    const onQueryChange = (e) => {
        const val = e.target.value
        setSearchText(val)
        if (!!debounceRef.current.latest) {
            debounceRef.current.latest.cancel()
        }
        const querySearch = _.debounce(() => {
            if (searchQueries.search !== val) {
                setSearchQueries({ ...searchQueries, search: val, page: 0 })
            }
        }, 1000)
        debounceRef.current.latest = querySearch
        querySearch()
    }

    const onItemDetails = (id) => {
        if (!isLoading) {
            toggleDetailModal()
            setDetailTargetId(+id)
        }
    }

    const toggleDetailModal = () => {
        if (!isLoading && !isLoadingDetail) {
            const tmpModal = detailModal
            setDetailModal(!detailModal)
            if (!!tmpModal) {
                setDetailTargetId(undefined)
                setDetailInfo(undefined)
            }
        }
    };

    return (
        <div className={style["page-root"]}>
            <div className="col-12 col-md-9 pt-4 pb-2">
                {localStorage.getItem('userMode') === "admin" &&
                    <button className={Classnames("btn", "btn-primary", "btn-md", "ml-1")} onClick={onNewPatient}>+ New Patient</button>
                }
                <button className={Classnames("btn", "btn-primary", "btn-md", "ml-2")} onClick={onConfirm}>Export Patients</button>
                <button className={Classnames("btn", "btn-primary", "btn-md", "ml-2")} onClick={onConfirmActivity}>Export Activities</button>
                <button className={Classnames("btn", "btn-primary", "btn-md", "ml-2")} onClick={onConfirmEvent}>Export Events</button>
            </div>
            <Modal isOpen={detailModal} toggle={toggleDetailModal}>
                <ModalHeader toggle={toggleDetailModal}>Patient details</ModalHeader>
                <ModalBody>
                    {
                        isLoadingDetail ? (
                            <Skeleton count={5} />
                        ) : (
                            <>
                                <div className="col-12">
                                    <span className={style["info-title"]}>Allergies:</span>
                                    <span className={style["info-value"]}>{!!detailInfo ? detailInfo.allergies : "-"}</span>
                                </div>
                                <div className="col-12">
                                    <span className={style["info-title"]}>Consultant:</span>
                                    <span className={style["info-value"]}>{!!detailInfo ? detailInfo.consultant : "-"}</span>
                                </div>
                                <div className="col-12">
                                    <span className={style["info-title"]}>Subtrial name:</span>
                                    <span className={style["info-value"]}>{!!detailInfo ? detailInfo.sub_trial_name : "-"}</span>
                                </div>
                                <div className="col-12">
                                    <span className={style["info-title"]}>Handedness:</span>
                                    <span className={style["info-value"]}>{!!detailInfo ? detailInfo.handedness : "-"}</span>
                                </div>
                                <div className="col-12">
                                    <span className={style["info-title"]}>Allergies:</span>
                                    <span className={style["info-value"]}>{!!detailInfo ? detailInfo.allergies : "-"}</span>
                                </div>
                                <div className="col-12">
                                    <span className={style["info-title"]}>Intelectual disabilities:</span>
                                    <span className={style["info-value"]}>{!!detailInfo ? detailInfo.intellectual_disabilities : "-"}</span>
                                </div>

                                <div className="col-12">
                                    <span className={style["info-title"]}>Medicare number:</span>
                                    <span className={style["info-value"]}>{!!detailInfo ? detailInfo.medicare_number : "-"}</span>
                                </div>
                                <div className="col-12">
                                    <span className={style["info-title"]}>Address:</span>
                                    <span className={style["info-value"]}>{!!detailInfo ? detailInfo.address : "-"}</span>
                                </div>
                                <div className="col-12">
                                    <span className={style["info-title"]}>Seizure Type:</span>
                                    <span className={style["info-value"]}>{!!detailInfo ? detailInfo.seizure_type : "-"}</span>
                                </div>
                                <div className="col-12">
                                    <span className={style["info-title"]}>Frequency:</span>
                                    <span className={style["info-value"]}>{!!detailInfo ? detailInfo.frequency : "-"}</span>
                                </div>
                                <div className="col-12">
                                    <span className={style["info-title"]}>Created at:</span>
                                    <span className={style["info-value"]}>{!!detailInfo ? moment(detailInfo.created_at).format('YYYY-MM-DD HH:mm') : "-"}</span>
                                </div>
                                <div className="col-12">
                                    <span className={style["info-title"]}>Updated at:</span>
                                    <span className={style["info-value"]}>{!!detailInfo ? moment(detailInfo.updated_at).format('YYYY-MM-DD HH:mm') : "-"}</span>
                                </div>
                                <div className="col-12">
                                    <span className={style["info-title"]}>Last followup:</span>
                                    <span className={style["info-value"]}>{!!detailInfo ? moment(detailInfo.last_follow_up).format('YYYY-MM-DD HH:mm') : "-"}</span>
                                </div>
                                <div className="col-12">
                                    <span className={style["info-title"]}>Number of AEDs at start:</span>
                                    <span className={style["info-value"]}>{!!detailInfo ? detailInfo.start_aed : "-"}</span>
                                </div>
                                <div className="col-12">
                                    <span className={style["info-title"]}>Number of AEDs prior to start:</span>
                                    <span className={style["info-value"]}>{!!detailInfo ? detailInfo.prior_start_aed : "-"}</span>
                                </div>
                                <div className="col-12">
                                    <span className={style["info-title"]}>Age of onset:</span>
                                    <span className={style["info-value"]}>{!!detailInfo ? detailInfo.age_of_onset : "-"}</span>
                                </div>

                                <div className="col-12">
                                    <span className={style["info-title"]}>Psychiatric history:</span>
                                    <span className={style["info-value"]}>{!!detailInfo ? detailInfo.psychiatric_history : "-"}</span>
                                </div>
                                <div className="col-12">
                                    <span className={style["info-title"]}>Psychiatric diagnosis:</span>
                                    <span className={style["info-value"]}>{!!detailInfo ? detailInfo.psychiatric_diagnosis : "-"}</span>
                                </div>
                                <div className="col-12">
                                    <span className={style["info-title"]}>Surgery history:</span>
                                    <span className={style["info-value"]}>{!!detailInfo ? detailInfo.surgery_history : "-"}</span>
                                </div>
                                <div className="col-12">
                                    <span className={style["info-title"]}>Currently on vns:</span>
                                    <span className={style["info-value"]}>{!!detailInfo && detailInfo.currently_on_vns !== null ? (!!detailInfo.currently_on_vns ? 'Yes' : 'No') : "-"}</span>
                                </div>
                                <div className="col-12">
                                    <span className={style["info-title"]}>VNS history:</span>
                                    <span className={style["info-value"]}>{!!detailInfo ? detailInfo.vns_history : "-"}</span>
                                </div>
                                <div className="col-12">
                                    <span className={style["info-title"]}>Site:</span>
                                    <span className={style["info-value"]}>{!!detailInfo ? detailInfo.site : "-"}</span>
                                </div>
                            </>
                        )
                    }
                </ModalBody>
            </Modal>
            {/* <TableFilter
                classes="col-12 col-md-9"
                textSearchField="search"
                query={searchText}
                sortedBy={searchQueries.sort}
                orderedBy={searchQueries.dir}
                orderDefault={initSearchQueries.dir}
                sortDefault={initSearchQueries.sort}
                onOrderChange={onOrderChange}
                onQueryChange={onQueryChange}
                onSortChange={onSortChange}
                orders={[{ label: "Ascending", value: "asc" }, { label: "Descending", value: "desc" }]}
                sorts={[{ label: "First name", value: "first_name" }, { label: "Last name", value: "sure_name" }, { label: "Email", value: "email" }]}
            /> */}
            <div className="col-12 col-md-9 table-responsive">
                {
                    isLoading && locations.length === 0 ? (
                        <div className={style["page-root"]}>
                            Loading...
                        </div>
                    ) : (
                        <>
                            <Table striped>
                                <thead>
                                    <tr>
                                        <th style={{ width: "150px" }}>Email</th>
                                        <th style={{ width: "150px" }}>Username</th>
                                        <th style={{ width: "150px" }}>Subtrial</th>
                                        <th style={{ width: "150px" }}>Last update</th>
                                        <th style={{ width: "150px" }}>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {locations.map((el, index) => (
                                        <tr key={index}>
                                            <td>{el.email || "-"}</td>
                                            <td>{el.username || "-"}</td>
                                            <td>{el.subtrial || "-"}</td>
                                            {/* <td className={Classnames(style['subtrial-cell'])}>{!!el.sub_trial_name ? (el.sub_trial_name === 'synergy' ? 'pearls' : el.sub_trial_name) : "-"}</td> */}
                                            <td className={Classnames({ 'text-danger': (el.is_active && !!el.last_upload && moment().diff(el.last_upload, 'hours') > (7 * 24)), 'text-success': !el.is_active })}>{el.last_upload ? moment(el.last_upload).format('YYYY-MM-DD HH:mm') : "NO-DATA"}</td>
                                            <td>
                                                <ButtonDropdown
                                                    isOpen={!!actionRowId && actionRowId === el.id} toggle={onActionDropdownToggle.bind(this, el.id)}>
                                                    <DropdownToggle
                                                        caret={true}
                                                        disabled={isLoading}
                                                        size="sm"
                                                        color="primary"
                                                    // className={Classnames("btn", "btn-primary", "btn-sm", { "disabled": isLoading })}
                                                    >
                                                        Select Action
                                                    </DropdownToggle>
                                                    <DropdownMenu>
                                                        <DropdownItem
                                                            disabled={isLoading}
                                                            onClick={onPatienActivities.bind(this, el.id, study)}
                                                        >
                                                            Show Activities
                                                        </DropdownItem>
                                                        <DropdownItem
                                                            disabled={isLoading}
                                                            onClick={onPatientEvents.bind(this, el.id, study)}
                                                        >
                                                            Show Events
                                                        </DropdownItem>
                                                        <DropdownItem
                                                            disabled={isLoading}
                                                            onClick={onWidthdrawToggle.bind(this, el.id)}
                                                        >
                                                            {el.is_active ? 'Widthdraw' : 'Cancel Widthdraw'}
                                                        </DropdownItem>
                                                    </DropdownMenu>
                                                </ButtonDropdown>
                                            </td>
                                            {/* <td><i className={Classnames("fas fa-notes-medical", style["item-icon"])}></i></td> */}
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                            {
                                locations.length === 0 && (
                                    <div className={style["page-root"]}>
                                        No record available
                                    </div>
                                )
                            }
                        </>
                    )
                }
            </div>
            <div>
                <button className={Classnames("btn", "btn-primary", "btn-md", "mr-1", { "disabled": isLoading || searchQueries.page < 2 })} onClick={onPrevPage}>Prev page</button>
                <span>{`${totalCount > 0 ? searchQueries.page : "-"}/${totalCount > 0 ? Math.ceil(totalCount / searchQueries.limit) : "-"}`}</span>
                <button className={Classnames("btn", "btn-primary", "btn-md", "ml-1", { "disabled": isLoading || isEnd })} onClick={onNextPage}>Next page</button>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    token: state.auth.access_token,
    study: state.common.type
});

const mapDispatchToProps = (dispatch) => ({
    // startLoginUser: (body, remember) => dispatch(startLoginUser({body}, remember))
})

export default connect(mapStateToProps, mapDispatchToProps)(AdminPatients);