import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Table } from 'reactstrap';
import { fetchActivities } from './services';
import style from "./style.module.scss"
import Classnames from "classnames";
import moment from "moment";
import _ from "lodash";
import Skeleton from 'react-loading-skeleton';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { AppAlert } from "../../utils/Notification"

const AdminPatients = ({ token, history, match, study }) => {

    const initSearchQueries = {
        limit: 25,
        page: 1,
    }

    const [locations, setLocations] = useState([])
    const [isEnd, setEnd] = useState(false)
    const [detailModal, setDetailModal] = useState(false);
    const [searchQueries, setSearchQueries] = useState({ ...initSearchQueries })
    const [isLoading, setLoading] = useState(false)
    const [totalCount, setTotalCount] = useState(-1)
    const [isLoadingDetail, setLoadingDetail] = useState(false)
    const [detailInfo, setDetailInfo] = useState(undefined)
    const [detailTargetId, setDetailTargetId] = useState(undefined);

    useEffect(() => {
        if (!isLoading) {
            setLoading(true)
            fetchActivities({ id: match.params.id, parameter:{limit: searchQueries.limit, offset: (searchQueries.page -1)* searchQueries.limit,} , header: { authorization: `Bearer ${token}`} })
                .then((response) => {
                    setLocations(response.data.results || [])
                    setTotalCount(response.data.count)
                    setEnd(!response.data.next)
                })
                .catch(error => {
                    if (!error.response) {
                        AppAlert(500, "cannot reach server")
                    }
                    else {
                        AppAlert(error.response.status, error.response.data.detail)
                    }
                })
                .finally(() => {
                    setLoading(false)
                })
        }
    }, [searchQueries])

    useEffect(() => {
        setSearchQueries({...searchQueries, page: 1})
    }, [study])

    useEffect(() => {
        if (!!detailTargetId) {
            const target = locations.find((el) => el.id === detailTargetId)
            if (!!target) {
                setDetailInfo(target)
            }
            // setLoadingDetail(true)
            // setLoadingDetail(false)
        }
    }, [detailTargetId])

    const onNextPage = () => {
        if (!isEnd) {
            setSearchQueries({ ...searchQueries, page: searchQueries.page + 1 })
        }
    }

    const onPrevPage = () => {
        if (searchQueries.page > 1) {
            setSearchQueries({ ...searchQueries, page: searchQueries.page - 1 })
        }
    }

    const onItemDetails = (id) => {
        if (!isLoading) {
            toggleDetailModal()
            setDetailTargetId(+id)
        }
    }

    const toggleDetailModal = () => {
        if (!isLoading && !isLoadingDetail) {
            const tmpModal = detailModal
            setDetailModal(!detailModal)
            if (!!tmpModal) {
                setDetailTargetId(undefined)
                setDetailInfo(undefined)
            }
        }
    };

    const durationParser = (seconds) => {

        if (seconds >= 3600) {
            const y = Math.floor(seconds / (3600 * 24 * 365));
            const m = Math.floor(seconds % (3600 * 24 * 365) / (3600 * 24 * 30));
            const w = Math.floor(seconds % (3600 * 24 * 30) / (3600 * 24 * 7));
            const d = Math.floor(seconds % (3600 * 24 * 7) / (3600 * 24));
            const h = Math.floor(seconds % (3600 * 24) / 3600);

            const yDisplay = y > 0 ? y + (y == 1 ? " year, " : " years, ") : "";
            const mDisplay = m > 0 ? m + (m == 1 ? " month, " : " months, ") : "";
            const wDisplay = w > 0 ? w + (w == 1 ? " week, " : " weeks, ") : "";
            const dDisplay = d > 0 ? d + (d == 1 ? " day, " : " days, ") : "";
            const hDisplay = h > 0 ? h + (h == 1 ? " hour, " : " hours, ") : "";
            const res = yDisplay + mDisplay + wDisplay + dDisplay + hDisplay;
            return res || "-";
        }
        else {
            {
                const m = Math.floor(seconds / (60));
                const s = Math.floor(seconds % (60));

                const mDisplay = m > 0 ? m + (m == 1 ? " minute, " : " minutes, ") : "";
                const sDisplay = s > 0 ? s + (s == 1 ? " second, " : " seconds, ") : "";
                const res = mDisplay + sDisplay;
                return res || "-";
            }
        }
    }

    const handleFileClick = (uri) => {
        window.open(uri.url)
    }

    return (
        <div className={style["page-root"]}>
            <div className="col-12 col-md-9 pt-4 pb-2">
                <span className={style['study-name']}>{`Study: ${match.params.study}`}</span>
            </div>
            <Modal isOpen={detailModal} toggle={toggleDetailModal}>
                <ModalHeader toggle={toggleDetailModal}>Details</ModalHeader>
                <ModalBody>
                    {
                        isLoadingDetail ? (
                            <Skeleton count={5} />
                        ) : (
                                <>
                                    <div className="col-12">
                                        <span className={style["info-title"]}>Updated at:</span>
                                        <span className={style["info-value"]}>{!!detailInfo && detailInfo.updated_at ? moment(detailInfo.updated_at).format('YYYY-MM-DD HH:mm') : "-"}</span>
                                    </div>
                                    <div className="col-12">
                                        <span className={style["info-title"]}>Activity date:</span>
                                        <span className={style["info-value"]}>{!!detailInfo && detailInfo.activity_date ? moment(detailInfo.activity_date).format('YYYY-MM-DD HH:mm') : "-"}</span>
                                    </div>
                                    {!!detailInfo && detailInfo.log_files && (
                                        <div className="col-12">
                                            <span className={style["info-title"]}>Log files:</span>
                                            {
                                                detailInfo.log_files.length > 0 ? detailInfo.log_files.map((file, key) => (
                                                    <span onClick={handleFileClick.bind(this, file)} key={key} className={style["clickable-info-value"]}>{`file_${key + 1}`}</span>
                                                )) : (
                                                        <span className={style["info-value"]}>-</span>
                                                    )
                                            }
                                        </div>
                                    )}
                                </>
                            )
                    }
                </ModalBody>
            </Modal>
            <div className="col-12 col-md-9 table-responsive">
                {
                    isLoading ? (
                        <div className={style["page-root"]}>
                            Loading...
                        </div>
                    ) : (
                            <>
                                <Table striped>
                                    <thead>
                                        <tr>
                                            <th style={{ width: "150px" }}>Type</th>
                                            <th style={{ width: "150px" }}>Duration</th>
                                            <th style={{ width: "150px" }}>Creation Date</th>
                                            {/* <th style={{ width: "150px" }}>Activity Date</th> */}
                                            <th style={{ width: "150px" }}>Comment</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {locations.map((el, index) => (
                                            <tr key={index} >
                                                <td>{el.type || "-"}</td>
                                                <td>{!!el.duration ? durationParser(el.duration) : "-"}</td>
                                                <td>{el.created_at ? moment(el.created_at).format('YYYY-MM-DD HH:mm') : "-"}</td>
                                                {/* <td>{el.activity_date ? moment(el.activity_date).format('YYYY-MM-DD HH:mm') : "-"}</td> */}
                                                <td>{el.comment || "-"}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                                {
                                    locations.length === 0 && (
                                        <div className={style["page-root"]}>
                                            No record available
                                        </div>
                                    )
                                }
                            </>
                        )
                }
            </div>
            <div>
                <button className={Classnames("btn", "btn-primary", "btn-md", "mr-1", { "disabled": isLoading || searchQueries.page < 2 })} onClick={onPrevPage}>Prev page</button>
                <span>{`${totalCount > 0 ? searchQueries.page : "-"}/${totalCount > 0 ? Math.ceil(totalCount / searchQueries.limit) : "-"}`}</span>
                <button className={Classnames("btn", "btn-primary", "btn-md", "ml-1", { "disabled": isLoading || isEnd })} onClick={onNextPage}>Next page</button>
            </div>
        </div >
    )
}

const mapStateToProps = (state) => ({
    token: state.auth.access_token,
    study: state.common.type
});

const mapDispatchToProps = (dispatch) => ({
    // startLoginUser: (body, remember) => dispatch(startLoginUser({body}, remember))
})

export default connect(mapStateToProps, mapDispatchToProps)(AdminPatients);