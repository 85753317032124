import {LOGIN_ACTION, LOGOUT_ACTION, FETCH_USERS} from './constants'; 
import {userVerification, loginUser as loginService, logoutUser as logoutService, usersList} from '../services/User';

export const startLoginUser = (loginInfo, remember) => {
    return (dispatch, getState) => {
        return loginService(loginInfo)
            .then((response) => {
                dispatch(loginUser({access_token: response.data.access, refresh_token: response.data.refresh, profile: response.data.user}))
                if (remember){
                    localStorage.setItem('access_token', response.data.access)
                    localStorage.setItem('refresh_token', response.data.refresh)
                    localStorage.setItem('profile', JSON.stringify({name: 'user'}))
                }
                return {
                    success: {
                        status: response.status,
                        body: response.data
                    }
                };
            })
            .catch((error) => {
                return {
                    error: {
                        status: error.response.status,
                        body: error.response.data
                    }
                };
            })
    }
}

export const loginUser = (payload) => ({
    type: LOGIN_ACTION,
    payload
});

export const verifyUserAccess = (access_token="", refresh_token="", profile={}) => {
    return (dispatch, getState) => {
        return userVerification({
            header: {
                Authorization: `Bearer ${access_token}`
            }
        })
        .then((response) => {
            dispatch(loginUser({access_token: access_token, profile: profile, refresh_token: refresh_token}))
            return {
                success: {
                    status: response.status,
                    body: response.data
                }
            }
        })
        .catch((error) => {
            return {
                error: {
                    status: error.response.status,
                    body: error.response.data
                }
            };
        })
    }
};

export const startLogoutUser = () => {
    return (dispatch, getState) => {
        return logoutService({
            header: {
                Authorization: `${getState().auth.token}`
            }
        })
        .then((response) => {
            dispatch(logoutUser())
            localStorage.removeItem('authToken')
            return {
                success: {
                    status: response.status,
                    body: response.data
                }
            };
        })
        .catch((error) => {
            return {
                error: {
                    status: error.response.status,
                    body: error.response.data
                }
            };
        })
    }
};

export const logoutUser = () => ({
    type: LOGOUT_ACTION,
    payload: {}
});

export const startGetUsersList = (parameter) => {
    return (dispatch, getState) => {
        return usersList({
            header: {
                Authorization: `${getState().auth.token}`
            },
            parameter
        })
        .then((response) => {
            dispatch(fetchUsers(response.data))
            return {
                success: {
                    status: response.status,
                    body: response.data
                }
            }
        })
        .catch((error) => {
            return {
                error: {
                    status: error.response.status,
                    body: error.response.data
                }
            };
        })
    }
};

export const fetchUsers = (payload) => ({
    type: FETCH_USERS,
    payload
});