import React from 'react';
import { connect } from 'react-redux';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import PublicRoute from './PublicRoute';
import LoginPage from '../pages/LoginPage';
import NotFoundPage from '../components/NotFoundPage';
import PrivateRoute from './PrivateRoute'
import AdminPatients from '../pages/AdminPatients';
import ActivitiesLog from '../pages/ActivitiesLog';
import EventsLog from '../pages/EventsLog';

const AppRouter = ({ mode }) => {
    return (
        <BrowserRouter>
            <Switch>
                <PublicRoute path='/' component={LoginPage} exact={true} />
                {/* <PrivateRoute path='/dashboard' component={AdminDashboard} /> */}
                <PrivateRoute path='/patients' component={AdminPatients} />
                <PrivateRoute path='/activities/:id/:study' component={ActivitiesLog} />
                <PrivateRoute path='/events/:id/:study' component={EventsLog} />
                <Route component={NotFoundPage} />
            </Switch>
        </BrowserRouter>
    )
}

const mapStateToProps = (state) => ({
    mode: state.auth.mode
});

export default connect(mapStateToProps)(AppRouter);