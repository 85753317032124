import client from '../../services/SharedConfig';

export const fetchPatients = ({parameter={}, header={}} = {}) => {
    const config = {
        method: 'GET',
        url: '/api/v1/admin-panel/patient/',
        params: {
            ...parameter,
        },
        headers:{
            ...header
        }
    };
    return client.request(config )
};

export const toggleWithdraw = ({personId=0, header={}} = {}) => {
    const config = {
        method: 'POST',
        url: `/api/v1/admin-panel/patient/${personId}/withdraw/`,
        headers:{
            ...header
        }
    };
    return client.request(config )
};

export const subtrials = ({header={}} = {}) => {
    const config = {
        method: 'GET',
        url: `/api/v1/admin-panel/subtrials/`,
        headers:{
            ...header
        }
    };
    return client.request(config )
};

export const downloadExport = ({header={}, study=''} = {}) => {
    const config = {
        method: 'GET',
        responseType: "blob",
        url: `/api/v1/admin-panel/patient/export/?subtrial=${study}`,
        headers:{
            ...header
        }
    };
    return client.request(config )
};

export const fetPatientDetails = ({header={}, id="0"} = {}) => {
    const config = {
        method: 'GET',
        url: `/api/admin/patients/${id}`,
        headers:{
            ...header
        }
    };
    return client.request(config )
};