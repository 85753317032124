import {COMMON_CHANGE_TYPE} from '../actions/constants'

const defaultState = {
    type: ''
};

export default (state = defaultState, action) => {
    switch (action.type){
        case COMMON_CHANGE_TYPE:
            return {type: action.payload.type};
        default:
            return state;
    }
};
