import Axios from 'axios';
import { logoutUser } from '../actions/users';
import { refreshToken } from './User';

let store;

export const injectStore = (_store) => {
  store = _store;
};

const axiosAuthenticatedClient = Axios.create({
  baseURL: `${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_BASE_ADDRESS}`,
  responseType: 'json',
});

const axiosClient = Axios.create({
  baseURL: `${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_BASE_ADDRESS}`,
  responseType: 'json',
});

// Request interceptor for API calls
axiosAuthenticatedClient.interceptors.request.use(
  async config => {
    // console.log('presendddddddddddddddd', config, 'headersssss', axiosAuthenticatedClient.defaults.headers);

    if (!axiosAuthenticatedClient.defaults.headers.common['Authorization']) {
      const accessToken = localStorage.getItem('access_token')
      axiosAuthenticatedClient.defaults.headers.common[
        'Authorization'
      ] = `Bearer ${accessToken}`;
      if (config.headers && !config.headers.Authorization) {
        config.headers.Authorization = `${accessToken}`;
      }
    }

    return config;
  },
  error => {
    Promise.reject(error);
  },
);

// Response interceptor for API calls
axiosAuthenticatedClient.interceptors.response.use(
  response => {
    // console.log('responded gracefully', response);
    return response;
  },
  async function (error) {
    // console.log('responded with error', error.config);
    const originalRequest = error.config;
    if (error.response.status === 403) {
      if (!originalRequest._retry) {
        originalRequest._retry = true;
        // const refToken = await getData(REFRESH_TOKEN);
        const refToken = localStorage.getItem('refresh_token')
        const refTokenResponse = await refreshToken(refToken || '');
        if (refTokenResponse.data.data.access_token) {
          localStorage.setItem('access_token', refTokenResponse.data.data.access_token )
          // storeData(ACCESS_TOKEN, refTokenResponse.data.data.access_token);
          axiosAuthenticatedClient.defaults.headers.common[
            'Authorization'
          ] = `${refTokenResponse.data.data.access_token}`;
        }
        return axiosAuthenticatedClient(originalRequest);
      } else {
        localStorage.removeItem("access_token")
        localStorage.removeItem("refresh_token")
        localStorage.removeItem("profile")
        localStorage.removeItem('selected_trial')
        store.dispatch(logoutUser());
      }
    }
    return Promise.reject(error);
  },
);

export {axiosAuthenticatedClient, axiosClient};
